import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          LibFi Migration/Update Currently in progress
        </p>
        <a
          className="App-link"
          href="https://t.me/libfi_io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Telegram
        </a>
        <br/>
        <a
          className="App-link"
          href="https://discord.gg/libfi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Discord
        </a>
        <br/>
        <a
          className="App-link"
          href="https://libfi.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Libfi.io
        </a>
      </header>
    </div>
  );
}

export default App;
